import React from 'react';
import { Link } from 'gatsby';

import Layout from '~/components/layout';
import Section from '~/components/section';

export default function Error404({ ...props }) {
  return (
    <Layout title="404: Not Found" {...props}>
      <Section title="404: Not Found" id="intro" className="style1">
        <p className="style2">Oh no! Page not found.</p>
        <ul className="actions">
          <li><Link to="/" className="button style3 large">Go Home</Link></li>
        </ul>
      </Section>
    </Layout>
  );
}
